<template>
  <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <main>
      <div class="main_content align-items-center">
        <div class="row justify-content-between mb_40">
          <div class="col-md-12">
            <div class="heading">
              <h2 v-if="buttonUpdate">Update Email Template</h2>
              <h2 v-else>Add New Email Template</h2>
              <h3>PBE Management Dashboard</h3>
            </div>
          </div>
        </div>
        <form
          method="post"
          @submit.prevent="addEmailTemplate"
          data-vv-scope="addEmailTemplateForm"
        >
          <div class="card_wrap bg-white">
            <div class="card">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="disabledTextInput" class="form-label"
                      >Name <span> *</span> :</label
                    >
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control"
                        id="disabledTextInput"
                        placeholder="Type Here"
                        name="Name"
                        v-model="template_data.template_name"
                        v-validate="'required'"
                      />
                    </div>
                    <small
                      class="error text-danger"
                      v-show="errors.has('addEmailTemplateForm.Name')"
                    >
                      {{ errors.first("addEmailTemplateForm.Name") }}
                    </small>
                  </div>
                </div>
                <input type="hidden" v-model="template_data.id" />
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="disabledTextInput" class="form-label"
                      >Subject <span> *</span> :</label
                    >
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control"
                        id="disabledTextInput"
                        placeholder="Type Here"
                        name="Subject"
                        v-model="template_data.subject"
                        v-validate="'required'"
                      />
                    </div>
                    <small
                      class="error text-danger"
                      v-show="errors.has('addEmailTemplateForm.Subject')"
                    >
                      {{ errors.first("addEmailTemplateForm.Subject") }}
                    </small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 content">
                  <div class="client_accordian mb-0">
                    <div class="">
                      <h5 class="heading_4">Content</h5>
                    </div>
                    <div class="form-group">
                      <ckeditor
                        :editor="editor"
                        :config="editorConfig"
                        name="Content"
                        id="Content"
                        v-model="template_data.content"
                        v-validate="'required'"
                        @blur="onLostFocusEditor('addEmailTemplateForm.Content')"
                      >
                      </ckeditor>
                      <small
                        class="error text-danger"
                        v-show="errors.has('addEmailTemplateForm.Content')"
                      >
                        {{ errors.first("addEmailTemplateForm.Content") }}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="suggestion">
                    <p>
                      <strong>{user firstname}</strong> used for User's
                      firstname.
                    </p>
                    <p>
                      <strong>{formlink self assessment}</strong> used for
                      Self-Assessment link.
                    </p>
                    <p>
                      <strong>{formlink ergo eval}</strong> used for User's Ergo
                      Eval link.
                    </p>
                    <p>
                      <strong>{formlink schedule request}</strong> used for
                      schedule request
                    </p>
                    <p>
                      <strong>{formlink self assesment follow up}</strong> used
                      for Self-Assessment follow-up link.
                    </p>
                    <p>
                      <strong>{formlink ergo eval follow up}</strong> used for
                      Ergo Eval follow-up link.
                    </p>
                    <p>
                      <strong>{add client name}</strong> used for client name.
                    </p>
                    <p>
                      <strong>{formlink self assessment follow up}</strong> used
                      for Self-Assessment follow-up link.
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label for="disabledTextInput" class="form-label"
                      >Client <span> *</span> :</label
                    >
                    <v-select
                      :reduce="(client_list) => client_list.id"
                      :options="client_list"
                      label="name"
                      placeholder="Select Client"
                      name="Client"
                      v-model="template_data.customer_id"
                      v-validate="'required'"
                    />
                    <small
                      class="error text-danger"
                      v-show="errors.has('addEmailTemplateForm.Client')"
                    >
                      {{ errors.first("addEmailTemplateForm.Client") }}
                    </small>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label for="disabledTextInput" class="form-label"
                      >Type :</label
                    >
                    <v-select
                      :options="[
                        'Self-Assessment',
                        'Ergo Eval',
                        'Self Assesment Follow Up',
                        'Ergo Eval Follow Up',
                        'Reset Password',
                        'New Account Created',
                      ]"
                      label="name"
                      placeholder="Select Type"
                      name="type"
                      v-model="template_data.type"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label for="disabledTextInput" class="form-label"
                      >Include in CC :</label
                    >
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control"
                        id="disabledTextInput"
                        placeholder="Type Here"
                        name="CC"
                        v-model="template_data.cc"
                        v-validate="'multipleEmail'"
                      />
                    </div>
                    <small
                      class="error text-danger"
                      v-show="errors.has('addEmailTemplateForm.CC')"
                    >
                      {{ errors.first("addEmailTemplateForm.CC") }}
                    </small>
                    <p class="mb_0 mt_14 font_size_12">
                      Add emails to be CC when this email template is used, can add multiple comma-separated emails.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt_24">
            <div class="col-sm-12">
              <button v-if="buttonUpdate" type="submit" class="btn btn_primary">
                Update
              </button>
              <button v-else type="submit" class="btn btn_primary">
                Add New
              </button>
              <router-link
                :to="{ name: 'email_template' }"
                class="btn mob-btn btn_outline ml_24"
                >Cancel</router-link
              >
            </div>
          </div>
        </form>
      </div>
    </main>
    <!-- </div>
        </div>
    </div> -->
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import ClassicEditor from "ckeditor5-build-classic-image";
import commonFunction from "../../mixin/commonFunction";
export default {
  name: "add_new_template",
  mixins: [commonFunction],
  data() {
    return {
      editor: ClassicEditor,
      editorData:
        "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>",
      editorConfig: {
        // The configuration of the editor.
        toolbar: {
          items: ['heading', '|','bold', 'italic', 'link' , 'bulletedList', 'numberedList','|','undo','redo' ]
        },
      },
      client_list: [
        // { id: 2, name: "Pure Storage" },
        // { id: 3, name: "Atlassian" },
        // { id: 4, name: "PBE Lab Demo" },
        // { id: 7, name: "PBE Office Demo" },
        // { id: 5, name: "Twitch" },
        // { id: 6, name: "Zendesk" },
      ],
      buttonUpdate: false,
      template_data: {
        id: "",
        template_name: "",
        sender_name: "",
        subject: "",
        content: "",
        customer_id: "",
        type: "",
        cc: "",
      },
    };
  },
  mounted() {
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
    if (this.$route.params.id != undefined) {
      this.buttonUpdate = true;
      this.getSingleTemplate(this.$route.params.id);
    }
    this.getClients();
  },
  methods: {
    addEmailTemplate() {
      if (localStorage.getItem("userToken")) {
        var _this = this;
        _this.$validator.validateAll("addEmailTemplateForm").then((isValid) => {
          if (isValid) {
            if (_this.template_data.id) {
              //   _this.$parent.isLoader = true;
              let config = {
                method: "put",
                url:
                  process.env.VUE_APP_API_URL +
                  "/api/update-template/" +
                  _this.template_data.id,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${window.localStorage.getItem(
                    "userToken"
                  )}`,
                },
                data: _this.template_data,
              };
              _this
                .axios(config)
                .then(({ data }) => {
                  console.log("Template Updated", data);
                  _this.$toast.success(data.message, {
                    position: "top-right",
                    duration: 5000,
                  });
                  _this.$router.push({ name: "email_template" });
                })
                .catch(({ response }) => {
                  console.log("Error", response);
                });
            } else {
              let config = {
                method: "post",
                url: process.env.VUE_APP_API_URL + "/api/create-template",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${window.localStorage.getItem(
                    "userToken"
                  )}`,
                },
                data: this.encodeAPIRequest(_this.template_data),
              };
              _this
                .axios(config)
                .then(({ data }) => {
                  console.log("Template Added", data);
                  _this.$toast.success(data.message, {
                    position: "top-right",
                    duration: 5000,
                  });
                  _this.$router.push({ name: "email_template" });
                })
                .catch(({ response }) => {
                  if(response.status==422){
                    var err = '';
                    for (let x in response.data.error) {
                      err +=  response.data.error[x];
                      err += '<br>';
                    }
                    _this.$toast.error(err, {
                      position: "top-right",
                      duration: 5000,
                    });
                  } else{
                    _this.$toast.error(response.data.error, {
                      position: "top-right",
                      duration: 5000,
                    });
                  }
                });
              //   _this.$parent.isLoader = false;
            }
          } else {
            console.log("Error In Validation", _this.errors);
            //   _this.$parent.isLoader = false;
          }
        });
      } else {
        this.tokenExpired();
      }
    },
    getSingleTemplate(id) {
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/edit-template/" + id,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          console.log("SINGLE DATA", data);
          _this.template_data.id = data.templates.id;
          _this.template_data.customer_id = data.templates.customer_id;
          _this.template_data.template_name = data.templates.template_name;
          _this.template_data.subject = data.templates.subject;
          _this.template_data.content = data.templates.content;
          _this.template_data.cc = data.templates.cc;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    getClients() {
      var _this = this;
      if (localStorage.getItem("userToken")) {
        let config = {
          method: "get",
          url: process.env.VUE_APP_API_URL + "/api/active-clients",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
        };
        _this
          .axios(config)
          .then(({ data }) => {
            var decodedData = _this.decodeAPIResponse(data.data);
            decodedData.allClients.forEach((element) => {
              this.client_list.push({
                id: element.id,
                name: element.company_name,
              });
            });
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        _this.tokenExpired();
      }
    },
  },
};
</script>
